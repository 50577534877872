import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/containerview',
    children: [
      {
        path: 'containerview',
        name: 'containerview',
        component: () => import('../views/ContainerView.vue')//首页
      },
      {
        path: 'companyprofile',
        name: 'companyprofile',
        component: () => import('../views/CompanyProfile.vue')//企业简介
      },
     
      {
        path: 'companyequipment',
        name: 'companyequipment',
        component: () => import('../views/CompanyeQuipment.vue')//加工设备
      },
      {
        path: 'cararea',
        name: 'cararea',
        component: () => import('../views/CarArea.vue')//汽车领域
      },
      {
        path: 'medicalaviation',
        name: 'medicalaviation',
        component: () => import('../views/MedicalAviation.vue')//医疗航空
      },
      {
        path: 'electron',
        name: 'electron',
        component: () => import('../views/ElectronArea.vue')//电子电器
      },
      {
        path: 'machineryenergy',
        name: 'machineryenergy',
        component: () => import('../views/MachineryEnergy.vue')//机械能源
      },
      {
        path: 'peek',
        name: 'peek',
        component: () => import('../views/PeekView.vue'),//product
        props: true // 启用 props 传递 params
      },
      {
        path: 'pai',
        name: 'pai',
        component: () => import('../views/PaiView.vue')//pai
      },
      {
        path: 'ppsu',
        name: 'ppsu',
        component: () => import('../views/PpsuView.vue')//ppsu
      },
      {
        path: 'pei',
        name: 'pei',
        component: () => import('../views/PeiView.vue')//pei
      },
      {
        path: 'pps',
        name: 'pps',
        component: () => import('../views/PpsView.vue')//pps
      },
      {
        path: 'ppa',
        name: 'ppa',
        component: () => import('../views/PpaView.vue')//ppa
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('../views/ContactView.vue')//联系我们
      }
    ]
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
