import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2d7bd792"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
export function render(_ctx, _cache) {
  const _component_header_view = _resolveComponent("header-view");
  const _component_router_view = _resolveComponent("router-view");
  const _component_footer_view = _resolveComponent("footer-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_header_view), _createVNode(_component_router_view), _createVNode(_component_footer_view, {
    class: "foot"
  })]);
}